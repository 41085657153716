import { Controller } from "@hotwired/stimulus";
import EditorJS from "@editorjs/editorjs";
import Header from "@editorjs/header";
import List from "@editorjs/list";
import Embed from "@editorjs/embed";
import Paragraph from "@editorjs/paragraph";
import ImageTool from "@editorjs/image";
import Quote from "@editorjs/quote";


export default class extends Controller {
  static targets = ["editorField", "hiddenField"];

  connect() {

    const csrfToken = document.querySelector('meta[name="csrf-token"]').content;

    // Initialize Editor.js
    this.editor = new EditorJS({
      holder: this.editorFieldTarget.id,
      onChange: () => this.updateContent(),
      tools: {
        header: {
          class: Header,
          inlineToolbar: ['link'],
        },
        list: {
          class: List,
          inlineToolbar: true,
        },
        embed: {
          class: Embed,
          inlineToolbar: true,
          config: {
            services: {
              youtube: true,
            },
          },
        },
        paragraph: {
          class: Paragraph,
          inlineToolbar: true,
        },
        quote: {
          class: Quote,
          inlineToolbar: true,
        },
        image: {
          class: ImageTool,
          inlineToolbar: true,
          config: {
            endpoints: {
              byFile: '/uploadFile',
              byUrl: '/fetchUrl',
            },
            additionalRequestHeaders: {
              'X-CSRF-Token': csrfToken,
            },
          },
        },
      },
      data: this._parseInitialContent(),
    });
  }

  disconnect() {
    // Destroy the Editor.js instance when the controller disconnects
    this.editor.destroy();
  }

  updateContent() {
    // Save Editor.js content and store it in the hidden field
    this.editor.save().then((outputData) => {
      this.hiddenFieldTarget.value = JSON.stringify(outputData);
    }).catch((error) => {
      console.error("Saving failed: ", error);
    });
  }

  _parseInitialContent() {
    // Parse the initial content from the hidden field
    try {
      return JSON.parse(this.hiddenFieldTarget.value || "{}");
    } catch (e) {
      console.error("Failed to parse initial content: ", e);
      return {};
    }
  }
}
