import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["hiddenField", "dropdownLabel", "dropdownMenu"];

  connect() {
    this.element.querySelectorAll(".dropdown-content a").forEach(link => {
      link.addEventListener("click", this.selectOption.bind(this));
    });
  }

  selectOption(event) {
    event.preventDefault();
    const link = event.currentTarget;
    const value = link.dataset.value;
    const label = link.textContent;

    this.hiddenFieldTarget.value = value;

    this.dropdownLabelTarget.textContent = label;

    this.dropdownMenuTarget.classList.add("hidden");
  }

  toggleDropdown(event) {
    event.preventDefault();
    this.dropdownMenuTarget.classList.toggle("hidden");
  }
}
