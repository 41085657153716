import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["error"]

  submit(event) {
    event.preventDefault()
    const form = this.element

    // Hide any previous error message
    this.errorTarget.classList.add("hidden")
    this.errorTarget.textContent = ""

    fetch(form.action, {
      method: 'POST',
      body: new FormData(form),
      headers: {
        'Accept': 'text/vnd.turbo-stream.html',
        'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content
      }
    })
    .then(response => response.text())
    .then(html => {
      Turbo.renderStreamMessage(html)
      subscribe_modal.close()
    })
    .catch(error => {
      console.error('Error:', error)
      this.errorTarget.textContent = "An unexpected error occurred. Please try again."
      this.errorTarget.classList.remove("hidden")
    })
  }
}
